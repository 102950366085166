/*!
 * bootstrap-tokenfield
 * https://github.com/sliptree/bootstrap-tokenfield
 * Copyright 2013-2014 Sliptree and other contributors; Licensed MIT
 */

/* General Typeahead styling, from http://jsfiddle.net/ragulka/Dy9au/1/ */
.twitter-typeahead {
  width: 100%;
  position: relative;
  vertical-align: top;
}
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
  margin: 0;
  width: 100%;
  vertical-align: middle;
  background-color: @input-bg;
}
.twitter-typeahead .tt-hint {
  color: @input-color-placeholder;
  z-index: 1;
  border: 1px solid transparent;
}
.twitter-typeahead .tt-input {
  color: @input-color;
  z-index: 2;
}
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
  height: @input-height-base;
  padding: @padding-base-vertical @padding-base-horizontal;
  font-size: 14px;
  line-height: 1.428571429;
}
.twitter-typeahead .input-sm.tt-input,
.twitter-typeahead .hint-sm.tt-hint {
  border-radius: 3px;
}
.twitter-typeahead .input-lg.tt-input,
.twitter-typeahead .hint-lg.tt-hint {
  border-radius: 6px;
}
.input-group .twitter-typeahead:first-child .tt-input,
.input-group .twitter-typeahead:first-child .tt-hint {
  border-radius: 4px 0 0 4px !important;
}
.input-group .twitter-typeahead:last-child .tt-input,
.input-group .twitter-typeahead:last-child .tt-hint {
  border-radius: 0 4px 4px 0 !important;
}
.input-group.input-group-sm .twitter-typeahead:first-child .tt-input,
.input-group.input-group-sm .twitter-typeahead:first-child .tt-hint {
  border-radius: 3px 0 0 3px !important;
}
.input-group.input-group-sm .twitter-typeahead:last-child .tt-input,
.input-group.input-group-sm .twitter-typeahead:last-child .tt-hint {
  border-radius: 0 3px 3px 0 !important;
}
.input-sm.tt-input,
.hint-sm.tt-hint,
.input-group.input-group-sm .tt-input,
.input-group.input-group-sm .tt-hint {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.input-group.input-group-lg .twitter-typeahead:first-child .tt-input,
.input-group.input-group-lg .twitter-typeahead:first-child .tt-hint {
  border-radius: 6px 0 0 6px !important;
}
.input-group.input-group-lg .twitter-typeahead:last-child .tt-input,
.input-group.input-group-lg .twitter-typeahead:last-child .tt-hint {
  border-radius: 0 6px 6px 0 !important;
}
.input-lg.tt-input,
.hint-lg.tt-hint,
.input-group.input-group-lg .tt-input,
.input-group.input-group-lg .tt-hint {
  height: 45px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
}
.tt-dropdown-menu {
  width: 100%;
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: @dropdown-bg;
  border: 1px solid #ccc;
  border: 1px solid @dropdown-border;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  border-radius: 6px;
  .box-shadow(0 5px 10px rgba(0, 0, 0, .2));
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.tt-suggestion {
  display: block;
  padding: 3px 20px;
}
.tt-suggestion.tt-cursor {
  color: @dropdown-link-hover-color;
  #gradient > .vertical(@dropdown-link-hover-bg, darken(@dropdown-link-hover-bg, 5%));
}
.tt-suggestion.tt-cursor a {
  color: @dropdown-bg;
}
.tt-suggestion p {
  margin: 0;
}

/* Tokenfield-specific Typeahead styling */
.tokenfield .twitter-typeahead {
  width: auto;
}
.tokenfield .twitter-typeahead .tt-hint {
  padding: 0;
  height: 20px;
}
.tokenfield.input-sm .twitter-typeahead .tt-input,
.tokenfield.input-sm .twitter-typeahead .tt-hint {
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
}
.tokenfield.input-lg .twitter-typeahead .tt-input,
.tokenfield.input-lg .twitter-typeahead .tt-hint {
  height: 23px;
  font-size: 18px;
  line-height: 1.33;
}
.tokenfield .twitter-typeahead .tt-suggestions {
  font-size: 14px;
}
