.flash-title {
  margin-top: 0;
  margin-bottom: 0;

  .label {
    padding: .125em .5em;
    font-size: 50%;
    vertical-align: middle;
  }
}

.flash-desc {
  font-size: 85%;
  letter-spacing: .15em;
  color: lighten(@gray, 20%);
}

.flash-primary,
.flash-success,
.flash-info,
.flash-warning,
.flash-danger {
  color: #fff;
  border-radius: 3px;

  .flash-desc {
    font-weight: normal;
    color: rgba(255,255,255,0.65);
  }

  .flash-hr {
    margin-left: -20px;
    margin-right: -20px;
    border-top-color: rgba(255,255,255,.2);
  }
}

.flash-primary { background-color: @brand-primary; }
.flash-success { background-color: @brand-success; }
.flash-info    { background-color: @brand-info; }
.flash-warning { background-color: @brand-warning; }
.flash-danger  { background-color: @brand-danger; }
