//
// Imports
//

@import "./variables.less";
@import "./components.less";


//
// Custom overrides by SearchFusion
//

ins {
  color: green;
  background: #dfd;
  text-decoration: none;
}

del {
  color: red;
  background: #fdd;
  text-decoration: line-through;
}

#feed_schedulingType label {
  padding-left: 5px;
  padding-right: 10px;
  font-weight: normal;
}

#progress-bar-wrapper {
  border: 1px solid #d5d5d5;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: -5px 0 0 10px;
  text-align: left;
  background: #fff;
  /*box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);*/
}

#progress-bar-wrapper .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: #1CA8DD;
  width: 0;
  /*box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);*/
}

#progress-bar-wrapper .status {
  top: 3px;
  left: 45%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
